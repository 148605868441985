:root {
  --blue: #064ea3;
  --collection-1-back: #f1eef8;
  --collection-1-business-blue: #064ea3;
  --collection-1-colordark-grey: #a5a8aa;
  --collection-1-colorstroke-grey: #e2e1e4;
  --collection-1-grey-1: #f9f9fb;
  --collection-1-semi-black: #0c0b0e;
  --collection-1-semi-black-duplicate: #0c0b0e;
  --collection-1-text: #00193d66;
  --collection-1-text-grey: #738085;
  --collection-1-white: #fff;
  --h2-font-family: "SF Pro Display", Helvetica;
  --h2-font-size: 24px;
  --h2-font-style: normal;
  --h2-font-weight: 400;
  --h2-letter-spacing: -.48px;
  --h2-line-height: 132.0%;
  --midnight-indigo: #121340;
  --personal-purple: #6c53b7;
}
/*# sourceMappingURL=index.0099a6d4.css.map */
