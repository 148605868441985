:root {
  --blue: rgba(6, 78, 163, 1);
  --collection-1-back: rgba(241, 238, 248, 1);
  --collection-1-business-blue: rgba(6, 78, 163, 1);
  --collection-1-colordark-grey: rgba(165, 168, 170, 1);
  --collection-1-colorstroke-grey: rgba(226, 225, 228, 1);
  --collection-1-grey-1: rgba(249, 249, 251, 1);
  --collection-1-semi-black: rgba(12, 11, 14, 1);
  --collection-1-semi-black-duplicate: rgba(12, 11, 14, 1);
  --collection-1-text: rgba(0, 25, 61, 0.4);
  --collection-1-text-grey: rgba(115, 128, 133, 1);
  --collection-1-white: rgba(255, 255, 255, 1);
  --h2-font-family: "SF Pro Display", Helvetica;
  --h2-font-size: 24px;
  --h2-font-style: normal;
  --h2-font-weight: 400;
  --h2-letter-spacing: -0.48px;
  --h2-line-height: 132.00000524520874%;
  --midnight-indigo: rgba(18, 19, 64, 1);
  --personal-purple: rgba(108, 83, 183, 1);
}
